import React from 'react';

const IconWhatsApp = () => {
  return (
    <>
      <svg
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8402 9.19688C11.809 9.18192 10.6426 8.60753 10.4353 8.53294C10.3507 8.50255 10.2601 8.47288 10.1636 8.47288C10.0061 8.47288 9.87382 8.55138 9.77076 8.70556C9.65424 8.87875 9.30151 9.29107 9.19252 9.41423C9.17829 9.43049 9.15886 9.44992 9.14722 9.44992C9.13678 9.44992 8.95626 9.37559 8.90162 9.35185C7.65034 8.80831 6.70058 7.50125 6.57034 7.28083C6.55175 7.24914 6.55097 7.23475 6.55081 7.23475C6.55538 7.21798 6.59746 7.17579 6.61918 7.15403C6.68271 7.09117 6.75154 7.00831 6.81814 6.92816C6.84967 6.89018 6.88125 6.85216 6.91227 6.81631C7.00889 6.7039 7.0519 6.61662 7.10177 6.51553L7.1279 6.46301C7.24967 6.22109 7.14567 6.01694 7.11206 5.95101C7.08447 5.89585 6.59195 4.70717 6.53959 4.58229C6.41367 4.28094 6.24728 4.14062 6.01606 4.14062C5.9946 4.14062 6.01606 4.14062 5.92608 4.14442C5.81653 4.14904 5.2199 4.22759 4.95611 4.39387C4.67637 4.57024 4.20312 5.13242 4.20312 6.12109C4.20312 7.01091 4.7678 7.85107 5.01024 8.1706C5.01627 8.17865 5.02733 8.19501 5.04338 8.21849C5.97185 9.57444 7.1293 10.5793 8.30265 11.0481C9.43226 11.4993 9.96717 11.5514 10.2713 11.5514C10.2713 11.5514 10.2713 11.5514 10.2713 11.5514C10.3991 11.5514 10.5014 11.5414 10.5916 11.5325L10.6489 11.5271C11.0391 11.4925 11.8967 11.0481 12.0917 10.506C12.2454 10.0791 12.2859 9.61257 12.1837 9.44327C12.1137 9.32816 11.993 9.27024 11.8402 9.19688Z"
          fill="#fff"
        />
        <path
          d="M8.14201 0C3.80871 0 0.283313 3.49891 0.283313 7.79964C0.283313 9.19065 0.655572 10.5523 1.36077 11.7439L0.0110009 15.7255C-0.0141419 15.7997 0.00455936 15.8818 0.0594685 15.9377C0.0991048 15.9782 0.152871 16 0.20778 16C0.228819 16 0.250014 15.9968 0.270689 15.9902L4.42238 14.671C5.55848 15.278 6.84253 15.5984 8.14207 15.5984C12.4749 15.5984 16 12.0999 16 7.79964C16 3.49891 12.4749 0 8.14201 0ZM8.14201 13.9737C6.91921 13.9737 5.73484 13.6206 4.71677 12.9526C4.68253 12.9301 4.64279 12.9185 4.60279 12.9185C4.58165 12.9185 4.56046 12.9218 4.53983 12.9283L2.46009 13.5894L3.13147 11.6087C3.15318 11.5446 3.14233 11.4739 3.10233 11.4192C2.32705 10.3599 1.91723 9.10831 1.91723 7.79964C1.91723 4.39481 4.70965 1.62473 8.14196 1.62473C11.5739 1.62473 14.366 4.39481 14.366 7.79964C14.366 11.2041 11.574 13.9737 8.14201 13.9737Z"
          fill="#fff"
        />
      </svg>
    </>
  );
};
export default IconWhatsApp;
