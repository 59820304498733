import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import { InlineWidget } from 'react-calendly';
import SectionHero from './SectionHero';
import brandImage from '../../assets/free-consultation-banner.png';
import brandImageMob from '../../assets/free-consultation-banner-mobile.png';
import profile from '../../assets/tib-profile.jpeg';
import FooterContainer from '../FooterContainer/FooterContainer';
import { FormattedMessage } from 'react-intl';

const FreeConsultation = props => {
  const { scrollingDisabled, schemaAvailability, css, topbar, intl, config, location } = props;
  const width = typeof window !== 'undefined' && window.innerWidth;
  const metaTitle = intl.formatMessage({ id: 'FreeBookingPage.metaTitle' });
  const metaDescription = intl.formatMessage({ id: 'FreeBookingPage.metaDescription' });
  const bannerAltText = intl.formatMessage({ id: 'FreeBookingPage.heroBannerAltText' });
  const therapistAltText = intl.formatMessage({ id: 'FreeBookingPage.therapistAltText' });

  return (
    <Page
      title={metaTitle}
      scrollingDisabled={scrollingDisabled}
      author={''}
      description={metaDescription}
      facebookImages={''}
      twitterImages={''}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: metaDescription,
        name: metaTitle,
        image: `${config.marketplaceRootURL}${profile}`,
        offers: {
          '@type': 'Offer',
          url: `${config.marketplaceRootURL}${location.pathname}`,
          price: '0',
          priceCurrency: 'USD',
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        {width > 768 ? (
          <div className={css.banner}>
            <img src={brandImage} alt={bannerAltText} width={'100%'} />
          </div>
        ) : (
          <div className={css.banner}>
            <img src={brandImageMob} alt={bannerAltText} width={'100%'} />
          </div>
        )}
        <div className={css.providerProfileWrapper}>
          <div className={css.providerProfile}>
            <div className={css.sectionLeft}>
              <section>
                {/* <h2 className={css.heading}>Therapy for the globalized South Asain</h2> */}
                <div>
                  <p className={css.para}>
                    Our Founder Jasmyn Rana is currently running matching consultations herself.
                    This is because finding the right match is the most integral part of your
                    healing journey. Book with Jasmyn to get matched to a South Asian therapist that
                    is best suited to you and your path.
                  </p>
                </div>
              </section>
            </div>
            <div className={css.sectionRight}>
              <section>
                <img className={css.image} alt={therapistAltText} src={profile}></img>
              </section>
            </div>
          </div>
        </div>

        <div className={css.wrapperCalender}>
          <h2 className={css.heading}>Hello, Let’s Talk !</h2>
          <p className={css.para}>
            Schedule a 30 min one-to-one call to discuss your goals and challenges
          </p>

          <div className={css.indicationText}>
            <FormattedMessage id="ListingPage.IndicationText" />
          </div>

          <InlineWidget
            url="https://calendly.com/jasmynrana-therapy/therapy-session?month=2024-01"
            styles={{
              flex: 1,
              minHeight: '660px',
              width: '100%',
              padding: 0,
            }}
            pageSettings={{
              // backgroundColor: 'ffffff',
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: '#000',
              textColor: '#000',
            }}
          ></InlineWidget>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default FreeConsultation;
