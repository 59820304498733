import React from 'react';
import css from './style.module.css';

const ToolTip = ({ children, tooltipText = '' }) => {
  return (
    <div class={css.tooltip}>
      {children}
      <span class={css.tooltiptext}>{tooltipText}</span>
    </div>
  );
};

export default ToolTip;
